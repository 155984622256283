<template>
  <base-section id="theme-features">
    <base-section-heading title="RESIDENCIAS">
    </base-section-heading>
    <!--7C99B0-->
    <v-container class="mt-n7">

      <v-row>
        <v-col>
          <v-card elevation="0">


            <v-row>

              <!-- HOSPITAL CIVIL DE GUADALAJARA “FRAY ANTONIO ALCALDE” -->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      HOSPITAL CIVIL DE GUADALAJARA “FRAY ANTONIO ALCALDE”
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad de Guadalajara
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Hospital  No. 278
                    </div>
                    <div>
                      Colonia Centro
                    </div>
                    <div>
                      C.P. 44280
                    </div>
                    <div>
                      Guadalajara, Jalisco
                    </div>
                    <div>
                      Tel. 33-3614-0482
                    </div>
                    <div>
                      email: ramonsigala@hotmail.com
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- Hospital General de Zona No. 20 IMSS La Margarita -->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      Hospital General de Zona No. 20 IMSS "La Margarita"
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Popular Autónoma del Estado de Puebla (UPAEP)
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Av. Fidel Velázquez No. 4211
                    </div>
                    <div>
                      Colonia Unidad Habitacional La Margarita
                    </div>
                    <div>
                      C.P. 72560
                    </div>
                    <div>
                      Puebla, Puebla
                    </div>
                    <div>
                      Tel. 22-2233-8877
                    </div>
                    <div>
                      Tel. 22-27806-346
                    </div>
                    <div>
                      email: israel.aguilarc@imss.gob.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- HOSPITAL CARDIOLOGIA SIGLO XXI -->
              <v-col cols="12">
                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >HOSPITAL DE CARDIOLOGÍA CENTRO MÉDICO NACIONAL SIGLO XXI
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Nacional Autónoma de México
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">
                      Instituto Mexicano del Seguro Social
                    </v-list-item-subtitle>
                    <div>
                      Av. Cuauhtemoc No. 330
                    </div>
                    <div>
                      Colonia Doctores
                    </div>
                    <div>
                      C.P. 06720
                    </div>

                    <div>
                      Ciudad de México
                    </div>
                    <div class="mt-1">
                      Tel. 555-627-6900 Ext. 22051
                    </div>
                    <div>
                      email: calidadlabcardio@gmail.com
                    </div>
                    <div>
                      email: fatima.castillo@imss.gob.mx
                    </div>
                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!--  Hosptal de Especialidades, Banco Central de Sangre Centro Médico Nacional Siglo XXI-->
              <v-col cols="12">
                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      Hospital de Especialidades, Banco Central de Sangre Centro Médico Nacional Siglo XXI
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad de Guanajuato
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">
                      Instituto Mexicano del Seguro Social
                    </v-list-item-subtitle>
                    <div>
                      Av. Cuauhtemoc No. 330
                    </div>
                    <div>
                      Colonia Doctores
                    </div>
                    <div>
                      C.P. 06720
                    </div>
                    <div>
                      Ciudad de México
                    </div>
                    <div>
                      Tel. 555-627-6900 Ext. 22731
                    </div>
                    <div>
                      email: maria.betancourta@imss.gob.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!--  Hospital General de Zona y Unidad de Medicina Familiar No. 1 "Lic. Ignacio García Téllez-->
              <v-col cols="12">
                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      Hospital General de Zona y Unidad de Medicina Familiar No. 1 "Lic. Ignacio García Téllez
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Autónoma de San Luis Potosí (UASLP)
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">
                      <!--Instituto Mexicano del Seguro Social-->
                    </v-list-item-subtitle>
                    <div>
                      Av. Nicolás Zapata No. 103
                    </div>
                    <div>
                      Colonia Tequisquiapan
                    </div>
                    <div>
                      C.P. 78250
                    </div>

                    <div>
                      San Luis Potosí
                    </div>
                    <div>
                      Tel. 444-812-9446
                    </div>
                    <div>
                      Tel. 417-1132-369
                    </div>
                    <div>
                      email: med.ana.reyes@gmail.com
                    </div>
                    <div>
                      email: ana.reyesp@imss.gob.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!--  Hospital de Especialidades UMAE No. 25 IMSS, Monterrey-->
              <v-col cols="12">
                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      Hospital de Especialidades UMAE No. 25 IMSS, Monterrey
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad de Monterrey
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">
                      <!--Instituto Mexicano del Seguro Social-->
                    </v-list-item-subtitle>
                    <div>
                      Av. Fidel Velázquez s/n
                    </div>
                    <div>
                      Colonia Mitras Norte
                    </div>
                    <div>
                      C.P. 64180
                    </div>

                    <div>
                      Monterrey, Nuevo León
                    </div>
                    <div>
                      Tel. 818-371-4100 Ext. 41415
                    </div>

                    <div>
                      email: miguel.riveraal@imss.gob.mx
                    </div>
                    <div>
                      email: raulramos2@hotmail.com
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!--Instituto Nacional de Ciencias Médicas y Nutrición Salvador Zubirán-->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      Instituto Nacional de Ciencias Médicas y Nutrición Salvador Zubirán
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Nacional Autónoma de México
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Vasco de Quiroga No. 15
                    </div>
                    <div>
                      Colonia Belisario Domínguez
                    </div>
                    <div>
                      C.P. 14080
                    </div>
                    <div>
                      Ciudad de México
                    </div>
                    <div>
                      Tel. 555-487-0900
                    </div>
                    <div>
                      email: raymundo.vladeze@incmnsz.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- HHOSPITAL UNIVERSITARIO “Dr. José Eleuterio González -->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >HOSPITAL UNIVERSITARIO “Dr. José Eleuterio González” UANL
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Autónoma de Nuevo León
                    </v-list-item-subtitle>
                    <div>
                      Av. Gonzalitos y Madero SN Primer Piso, Edificio de radiodiagnóstico
                    </div>
                    <div>
                      Colonia Mitras Centro
                    </div>
                    <div>
                      C.P. 64460
                    </div>
                    <div>
                      Monterrey, Nuevo León
                    </div>
                    <div class="mt-1">
                      Tel. 818-348-1036
                    </div>
                    <div>
                      email: jorge.llacadz@uanl.edu.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- HOSPITAL ANGELES PEDREGAL -->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      HOSPITAL ANGELES PEDREGAL
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Nacional Autónoma de México
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Camino a Santa Teresa No. 1055
                    </div>
                    <div>
                      Colonia Héroes de Padierna, Magdalena Contreras
                    </div>
                    <div>
                      C.P. 10700
                    </div>
                    <div>
                      Ciudad de México
                    </div>
                    <div>
                      Tel.555-449-5500
                    </div>
                    <div>
                      email: nancy.geronimo@saludangeles.mx
                    </div>
                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- LABORATORIOS CLÍNICOS DE PUEBLA -->
              <v-col cols="12">
                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      LABORATORIOS CLÍNICOS DE PUEBLA (LABORATORIOS RUÍZ)
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Popular Autónoma del Estado de Puebla (UPAEP)
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Mártires del 2 de Octubre No. 802
                    </div>
                    <div>
                      Colonia Anzures
                    </div>
                    <div>
                      C.P. 72530
                    </div>
                    <div>
                      Puebla, Puebla
                    </div>
                    <div>
                      Tel. 222-243-8100
                    </div>
                    <div>
                      email: abilene.flores@synlab.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>





              <!-- LABORATORIO DE PATOLOGÍA CLÍNICA FUNDACIÓN CLÍNICA MÉDICA SUR -->

              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                     LABORATORIO DE PATOLOGÍA CLÍNICA HOSPITAL MÉDICA SUR
                    </div>
                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Nacional Autónoma de México
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Puente de Piedra No. 150
                    </div>
                    <div>
                     Colonia Toriello Guerra
                    </div>
                    <div>
                      C.P. 14050
                    </div>
                    <div>
                      Ciudad de México
                    </div>
                    <div>
                      Tel. 555-424-7200 Ext. 6800
                    </div>
                    <div>
                      email: vnoffaln@medicasur.org.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>


              <!-- HOSPITAL CHRISTUS MUGUERZA -->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      HOSPITAL CHRISTUS MUGUERZA DE ALTA ESPECIALIDAD
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad de Monterrey
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Hidalgo No. 2525 Pte.
                    </div>
                    <div>
                      Colonia Obispado
                    </div>
                    <div>
                      C.P. 64060
                    </div>
                    <div>
                      Monterrey, Nuevo León
                    </div>
                    <div>
                     Tel. 818-399-3471
                    </div>
                    <div>
                      email: jvera@christus.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>


              <!-- Hospital Central Militar  NUEVO NOV 2024-->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      Hospital Central Militar
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad del Ejército y Fuerza Aérea (UEFAM)
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Boulevard Ávila Camacho SN
                    </div>
                    <div>
                      Colonia Lomas de Sotelo
                    </div>
                    <div>
                      C.P. 11600
                    </div>
                    <div>
                      Ciudad de México
                    </div>
                    <div>
                      Tel.55-2122-1100
                    </div>
                    <div>
                      email: becid2008@hotmail.es
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>



              <!-- CENTRO MÉDICO "LA RAZA" HOSPITAL GENERAL "GAUDENCIO GONZÁLEZ GARZA" -->
              <v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      CENTRO MÉDICO "LA RAZA" HOSPITAL GENERAL "GAUDENCIO GONZÁLEZ GARZA"
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Nacional Autónoma de México
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      P de las Jacarandas S/N
                    </div>
                    <div>
                      Colonia Azcapotzalco
                    </div>
                    <div>
                      C.P. 02990
                    </div>
                    <div>
                      Ciudad de México
                    </div>

                    <div>
                      Tel. 800-623-2323
                    </div>
                    <div>
                      email: teresa.ramosc@imss.gob.mx
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>


              <!-- HOSPITAL MILITAR DE ESPECIALIDADES, MUJER Y NEONATOLOGIA  NO VIENE EN LA LISTA DE LA DRA ROXANA NOV 2024-->
              <!--<v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      HOSPITAL MILITAR DE ESPECIALIDADES, MUJER Y NEONATOLOGIA
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">

                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Av. Industria Militar S/N
                    </div>
                    <div>
                      Col. Lomas de Sotelo
                    </div>
                    <div>
                      C. P. 11200
                    </div>
                    <div>
                      México, D. F.
                    </div>
                    <div>
                      Tel. 55 - 5587- 3300 ext. 3505
                    </div>
                    <div>
                      email: ignacioramirez19@me.com
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>-->



              <!--UNIDAD DE ESPECIALIDADES UMAE 25 IMSS  SE ACTUALIZO MAS ARRIBA-->
              <!--<v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      UNIDAD DE ESPECIALIDADES UMAE 25 IMSS
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad de Monterrey
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Av Fidel Velázquez s/n
                    </div>
                    <div>
                      Col. Mitras Nte.
                    </div>
                    <div>
                      C.P. 64180
                    </div>
                    <div>
                      Monterrey, N.L.
                    </div>
                    <div>
                      Tel. 81-8215-1291
                    </div>
                    <div>
                      email: blanca.perez@udem.edu
                    </div>
                    <div>
                      email: ravr05@hotmail.com
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>-->

              <!-- NO ESTA EN LA LISTA DE NOV 2024 HOSPITAL DE ESPECIALIDADES NO. 1 CENTRO MÉDICO NACIONAL BAJÍO -->
              <!--<v-col cols="12">

                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      HOSPITAL DE ESPECIALIDADES NO. 1 CENTRO MÉDICO NACIONAL BAJÍO
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad de Guanajuato
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mb-3 font-weight-bold h6 grey--text text--darken-2">

                    </v-list-item-subtitle>
                    <div>
                      Blvd. Adolfo López Mateos SN
                    </div>
                    <div>
                      Col. Los Paraisos
                    </div>
                    <div>
                      C.P. 37320
                    </div>
                    <div>
                      León, Gto.
                    </div>
                    <div>
                      Tel. 81-8215-1291
                    </div>
                    <div>
                      email: ronco_leon@hotmail.com
                    </div>

                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>-->

              <!-- HOPITAL ABC QUITAR A SOLICITUD E RA ROXANA NOV 2024 -->

              <!--<v-col cols="12">
                <v-list-item
                  :style="styles"
                  class="mb-2 px-0"
                >
                  <v-avatar
                    class="mr-6 align-self-start mt-2"
                    color="grey lighten-3"
                    size="76"
                  >
                    <v-icon color="blue"> mdi-office-building-marker-outline</v-icon>
                  </v-avatar>

                  <v-list-item-content>
                    <div
                      class="title text-uppercase mb-2"
                      v-text=""
                      style="color: #1E4165"
                    >
                      HOSPITAL ABC
                    </div>

                    <v-list-item-subtitle class="mb-3 font-weight-bold red--text text--darken-1">
                      Universidad Nacional Autónoma de México
                    </v-list-item-subtitle>
                    <div>
                      Sur 136 no. 116
                    </div>
                    <div>
                      Col. Las Américas
                    </div>
                    <div>
                      México D.F.
                    </div>
                    <div>
                      Jefatura de Banco de Sangre
                    </div>
                    <div>
                      Sótano Edificio Central
                    </div>
                    <div class="mt-1">
                      Tel. 555-230-8120 , 555-230-8000 ext. 8920 y 8921
                    </div>
                    <div>
                      email: menunezm@abchospital.com
                    </div>
                    <v-divider class="mt-2"/>
                  </v-list-item-content>
                </v-list-item>
              </v-col>-->






            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>

  export default {
    name: 'SectionResidencias',
    data: () => ({
      directivos: [
        {
          hospital: 'HOSPITAL UNIVERSITARIO',
          universidad:'Universidad Autónoma de Nuevo León',
          direccion:'Av. Francisco I. Madero Pte. y Av. Gonzalitos',
          colonia:'Col. Mitras Centro',
          estado:'Monterrey, Nuevo León',
          cp:'64460',
          telefono: '818-348-5711',
          email: 'dr_cazares@yahoo.com',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          hospital: 'HOSPITAL DE CARDIOLOGÍA CENTRO MÉDICO NACIONAL SIGLO XXI',
          universidad:'Centro Médico Nacional Siglo XXI',
          direccion:'Av. Francisco I. Madero Pte. y Av. Gonzalitos',
          colonia:'Col. Mitras Centro',
          estado:'Monterrey, Nuevo León',
          cp:'64460',
          telefono: '818-348-5711',
          email: 'dr_cazares@yahoo.com',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },

      ],
      size:56,
      tab: 'tab-3',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      emeritos: [
        {
          name: 'Dr. José Ramírez Barragán',
          puesto:'',
          hospital:'Hospital Ángeles del Carmen',
          direccion:'Guadajalara, Jalisco',
          telefono: '33-3813-0042',
          email: 'jose.ramirez@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Gilberto Aguilar Orozco',
          puesto:'',
          hospital:'Hospital Aranda de la Parra',
          direccion:'Hidalgo No. 329, C. P. 37000, León, Guanajuato.',
          telefono: '47-7713-3303',
          email: 'gilberto.aguilar@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Gilberto Aguilar Orozco',
          puesto:'',
          hospital:'Hospital Aranda de la Parra',
          direccion:'Hidalgo No. 329, C. P. 37000, León, Guanajuato.',
          telefono: '47-7713-3303',
          email: 'gilberto.aguilar@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Jesús Ignacio Simón Domínguez',
          puesto:'',
          hospital:'',
          direccion:'Ximilpa No. 31-4, C.P. 14000, México, D.F.',
          telefono: '55-5655-0198',
          email: 'jesus.simon@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        },
        {
          name: 'Dr. Luis Alberto Santoscoy Tovar',
          puesto:'',
          hospital:'Unidad de Patología Clínica',
          direccion:'Av. México No. 2341, C.P. 44650, Guadalajara, Jalisco.',
          telefono: '33-3669-0310',
          email: 'luis.santoscoy@compac.org.mx',
          reply: true,
          src: require('@/assets/user-2.jpg'),
        }
      ],
    }),
    computed: {
      classes () {
        return [
          this.outlined && 'base-avatar--outlined',
        ]
      },
      classes2 () {
        return [
          `text-${this.align}`,
          `mb-${this.space}`,
        ]
      },
      isDark () {
        return this.theme.isDark || this.dark
      },
      outlineSize () {
        return Number(this.size) + (this.size / this.multiply)
      },
      styles () {
        const margin = this.size / (this.multiply * 2)

        return {
          // Aligns the outline content with the content
          margin: `-${margin}px 0 0 -${margin}px`,
        }
      },
    },
  }
</script>

<style lang="sass">
  .base-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0

    &.base-avatar--outlined .base-avatar__avatar
      border-width: thin
      border-style: solid

    .theme--light .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(0, 0, 0, .12) !important

    .theme--dark .base-avatar--outlined .base-avatar__avatar
      border-color: rgba(255, 255, 255, .12) !important
</style>
